/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import _ from "underscore" // eslint-disable-line underscore-to-lodash/prefer-import-lodash
import { t } from "helpers/i18n"
import { is_updated, get_tag_values } from "./helpers"

export default [
  // step 1
  { name: "Name the Allowance" },
  // step 2
  {
    name: "Link to your payroll system",
    is_opening: function is_opening() {
      if (!this.opened || this.state.export_name === "") {
        // set the value to be the same as the name
        const name = this.rule.steps[0].state.name
        this.state.export_name = name
        this.element.find("#allowance_export_name").val(name)
      }
    },
  },
  // step 3
  { name: "What type of Allowance?" },
  // step 4
  {
    name: "Who will it apply to?",
    validator: function validator() {
      const tags_string = this.state.tags
      const negative_tags_string = this.state.negative_tags
      const tags_array = tags_string.split(",").filter((item) => item.trim() !== "")
      const negative_tags_array = negative_tags_string.split(",").filter((item) => item.trim() !== "")

      if (this.state.everyone === "0" && tags_array.length === 0) {
        return false
      }
      if (tags_array.some((item) => negative_tags_array.includes(item))) {
        return false
      }
      if (this.state.everyone === "1" && negative_tags_array.length > 0) {
        return false
      }

      return true
    },
    title_updater: function title_updater() {
      // if the provided title is not empty, use that instead
      // get the age val, if needed
      let age_title
      if (this.element.find(".advanced-link").hasClass("open")) {
        age_title = this.element.find(".range-input").get(0).get_title_wording()
      }
      const title = age_title ? " aged ".concat(age_title) : ""

      if (this.get_title()) {
        return this.get_title() + title
      }
      const el = this.element.find("select").get(0)
      if (el.is_filter_select) {
        const tags = this.element.find("select").get(0).filter_select_instance.val()

        return "Tags: ".concat(window.joinArray(tags, 2, "and", "other tag")) + title
      }

      return false
    },
    after_state_update: function after_state_update() {
      if (this.element.find("#allowance_everyone").val() === "1") {
        const clear = this.element.find(".award-tag-select-js").data("clearSelected")
        clear && clear()
        this.element.find("#allowance_tags").val("")
      }
      if (!this.element.find(".age-range-toggle-js").hasClass("selected")) {
        this.element.find("#allowance_min_age").val(0)
        this.element.find("#allowance_max_age").val(0)
      }
      if (!this.element.find(".probation-toggle-js").hasClass("selected")) {
        this.element.find("#allowance_probation").val(0)
      }
    },
    change_advanced_tab: function change_advanced_tab(closed) {
      // when advanced section collapsed, clear the contained fields
      if (closed) {
        this.element.find(".toggle-js").filter(".selected").click()
      }
    },
  },
  // step 5
  {
    name: "What day will it apply?",
    is_updated: is_updated,
    title_updater: function title_updater() {
      const public_holidays = this.element.find("#allowance_do_not_apply_on_public_holidays").prop("checked")
        ? " (not public holidays)"
        : ""
      if (this.get_title()) {
        return this.get_title().concat(public_holidays)
      }

      // otherwise, specific days must be selected
      const days = this.element
        .find("#specific-days-holder .selected")
        .map(function () {
          return this.innerText
        })
        .get()

      return "On ".concat(window.joinArray(days, 3, "and", "other")).concat(public_holidays)
    },
    validator: function validator() {
      // one must be selected
      return !(
        this.state.always === "0" &&
        (this.state.days === "false" || this.state.days === "") &&
        this.state.public_holidays === "0"
      )
    },
    after_state_update: function after_state_update() {
      const checkbox = this.element.find("#allowance_do_not_apply_on_public_holidays")
      checkbox
        .prop("disabled", this.state.public_holidays === "1")
        .prop("checked", this.state.public_holidays === "1" ? false : checkbox.prop("checked"))
      this.element.find(".specific-day-container").toggleClass("blue-border", this.state.public_holidays !== "1")
      function deselectDays(step) {
        step.element.find("#specific-days-holder").find(".selected").removeClass("selected")
      }
      if (this.state.always === "1") {
        // deselect all the days
        deselectDays(this)
        this.element.find("#allowance_do_not_apply_on_public_holidays").prop("disabled", false)
      } else if (this.state.public_holidays === "1") {
        deselectDays(this)
        this.element.find("#allowance_do_not_apply_on_public_holidays").prop("disabled", true).prop("checked", false)
      }
    },
  },
  // step 6
  {
    name: "What type of hours will it apply to?",
    is_updated: is_updated,
    title_updater: function () {
      const applicable_hours_type = this.element.find("#applicable_hours_type").val()
      let first_part = ""

      switch (applicable_hours_type) {
        case "overtime":
          first_part = t("js.allowances.form.label.overtime_hours")
          break
        case "ordinary":
          first_part = t("js.allowances.form.label.ordinary_hours")
          break
        default:
          first_part = t("js.allowances.form.label.all_hours")
          break
      }

      return first_part
    },
    after_state_update: function after_state_update() {
      const applicable_hours_type = this.element.find("#applicable_hours_type")
      if (this.element.find(".ordinary").hasClass("selected")) {
        applicable_hours_type.val("ordinary")
      }
      if (this.element.find(".overtime").hasClass("selected")) {
        applicable_hours_type.val("overtime")
      }
      if (this.element.find(".combined").hasClass("selected")) {
        applicable_hours_type.val(null)
      }
    },
  },
  // step 7
  {
    name: "What time will it apply?",
    is_updated: is_updated,
    validator: function validator() {
      if (this.state.all_hours === 1) {
        // set the inputs to empty strings
        this.state.times = ""
        this.state.start_times = ""
        this.state.end_times = ""
        return true
      }
      const res = this.element
        .find(".time-input-parent")
        .map(function () {
          return this.time_input_instances[0].validate()
        })
        .get()
      const u = _.uniq(res)
      // if we have at least 1 valid time frame, we're a-okay
      if ((u.length === 1 && u[0] === "empty") || res.indexOf(false) > -1) {
        // they are all empty here
        // we now need to mark them all as false
        this.element.find(".time-input-container").addClass("invalid")
        return false
      }

      this.element.find(".time-input-container").removeClass("invalid")
      return true
    },
    after_state_update: function after_state_update() {
      if (this.state.all_hours === 1) {
        // set the inputs to empty strings
        this.state.times = ""
        this.state.start_times = ""
        this.state.end_times = ""
      }
      _.chain(this.state)
        .keys()
        .filter(function (key) {
          return /times/.test(key)
        })
        .each(
          function (key) {
            this.element.find("#allowance_" + key).val(this.state[key])
          }.bind(this)
        )
        .value()
    },
  },
  // step 8
  {
    name: "Which shifts will it apply to?",
    is_updated: is_updated,
    validator: function validator() {
      // check the top part
      const s = this.state
      const part_a_valid =
        s.all_shifts === "1" ||
        +s.shift_gap > 0 ||
        (s.specific_shift_button === 1 && +s.from_shift > 0 && +s.to_shift > 0) ||
        (s.split_shifts === "1" && +s.split_shift_hours > 0) ||
        +s.longer_than > 0
      // if the button is clicked, but there are none included or excluded, it's invalid
      const award_invalid = this.state.award_tag_button && !this.state.award_include && !this.state.award_exclude
      const team_invalid = this.state.teams_tag_button && !this.state.teams_include && !this.state.teams_exclude
      this.element.find("#award-tag-opt").toggleClass("invalid", !!award_invalid)
      this.element.find("#team-opt").toggleClass("invalid", !!team_invalid)
      return part_a_valid && !award_invalid && !team_invalid
    },
    after_state_update: function () {
      const state = this.state
      _.each(["award", "teams"], function (tag) {
        if (!state[tag + "_tag_button"]) {
          _.each(["include", "exclude"], function (term) {
            state[tag + "_" + term] = ""
          })
        }
      })
      // this works but im sure theres a better way to do this
      this.state.shift_tags = get_tag_values(this.state.award_include, this.state.teams_include)
      this.state.not_shift_tags = get_tag_values(this.state.award_exclude, this.state.teams_exclude)
      if (this.state.award_tag_button || this.state.teams_tag_button) {
        this.element.find("#allowance_shift_tags").val(this.state.shift_tags)
        this.element.find("#allowance_not_shift_tags").val(this.state.not_shift_tags)
      } else {
        // clear out these fields
        this.element.find("#allowance_shift_tags").val("")
        this.element.find("#allowance_not_shift_tags").val("")
      }
      if (this.state.specific_shift_button !== 1) {
        // set these to 0
        this.element.find("#allowance_from_shift").val(0)
        this.element.find("#allowance_to_shift").val(0)
      }
    },
    title_updater: function () {
      const tags = this.state.award_include !== "" || this.state.award_exclude !== "" ? "Award" : ""
      const teams = this.state.teams_include !== "" || this.state.teams_exclude !== "" ? "Team" : ""
      const with_tags = _.compact([tags, teams]).join(" and ")
      let first_part = ""
      switch (true) {
        case this.state.all_shifts === "1":
          first_part = t("js.awards.form.title.all_shifts")
          break
        case this.state.longer_than !== false:
          first_part = "Longer than ".concat(this.state.longer_than, " hours")
          break
        case this.state.shift_gap !== false:
          first_part = "Shift gap: ".concat(this.state.shift_gap)
          break
        case this.state.split_shifts === "1" && this.state.max_split_shift_hours <= 0:
          first_part = "Split Shifts: ".concat(
            this.state.split_shift_hours,
            " ",
            +this.state.split_shift_hours === 1 ? "hour" : "hours",
            " break"
          )
          break
        case this.state.split_shifts === "1" && this.state.max_split_shift_hours > 0:
          first_part = "Split Shifts: ".concat(
            this.state.split_shift_hours,
            " ",
            +this.state.split_shift_hours === 1 ? "hour" : "hours",
            " break and will not apply after ",
            this.state.max_split_shift_hours,
            " ",
            +this.state.max_split_shift_hours === 1 ? "hour" : "hours",
            " break"
          )
          break
        case this.state.specific_shift_button === 1:
          first_part = "Between ".concat(this.state.from_shift, " and ", this.state.to_shift, " shifts")
          break
      }

      const with_leave = this.state.applies_on_leave ? " including leave" : " (except leave)"

      return first_part
        .concat(with_leave)
        .concat(with_tags ? " (selected " : "")
        .concat(with_tags)
        .concat(with_tags ? " Tags)" : "")
    },
  },
  // step 9
  {
    name: "How will it be paid during the shift?",
    is_updated: is_updated,
  },
  // step 10
  {
    name: "What rate is it paid at?",
    validator: function validator() {
      return !(this.state.specific_cost === 0 && this.state.multiplier_button === 0)
    },
    is_opening: function () {
      if (this.rule.is_manual) {
        this.element.find(".hide-if-manual-js").hide().find("input").val("")
      } else {
        this.element.find(".hide-if-manual-js").show()
      }

      if (this.rule.is_all_purpose) {
        this.element.find(".hide-if-all-purpose-js").hide().find("input").val("")
        this.element.find(".show-if-all-purpose-js").show()
      } else {
        this.element.find(".hide-if-all-purpose-js").show()
        this.element.find(".show-if-all-purpose-js").hide()
      }
    },
    title_updater: function () {
      const button = this.element.find(".selected")
      const text = button.find(".heading").text()
      const input = parseFloat(this.element.find(button.data("shows")).find("input").val(), 10)
      switch (text) {
        case "Specific Cost":
          return text.concat(": $", input.toFixed(2))
        case "Multiplier":
          return text.concat(": x", input)
        default:
          return ""
      }
    },
  },
]
